<template>
  <div class="p-3">
    <PageTitle>學生</PageTitle>
    <hr />
    <h4>查詢學生</h4>
    <el-form class="search" @submit.prevent.native="fetchStudents">
      <el-input
        placeholder="Please enter name/email"
        style="width: 100%"
        v-model="keyword"
      />
      <el-button type="primary" native-type="submit">
        Search
      </el-button>
    </el-form>
    <hr />
    <el-table :data="students.data">
      <el-table-column label="帳號狀態" width="60px" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.is_blocked"
            key="lock"
            type="danger"
            size="small"
            @click="switchBlockStatus(scope.row.id)"
          >
            <i class="fas fa-lock" />
          </el-button>
          <el-button
            v-else
            key="unlock"
            type="primary"
            size="small"
            @click="switchBlockStatus(scope.row.id)"
          >
            <i class="fas fa-lock-open" />
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Name" width="200px">
        <template slot-scope="scope">
          <router-link
            :to="{ name: 'ProfileForAdmin', params: { id: scope.row.id } }"
          >
            {{
              userMethods.displayName(scope.row.first_name, scope.row.last_name)
            }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Email">
        <template slot-scope="scope">
          {{ scope.row.email }}
          <span
            v-if="scope.row.email_verified_at"
            class="action-icon-without-pointer"
          >
            <i class="fas fa-check-circle" />
          </span>
        </template>
      </el-table-column>
      <el-table-column label="考卷數" width="100px">
        <template slot-scope="scope">
          {{ `${scope.row.user_exam_finished_count}/${scope.row.user_exam_count}` }}
        </template>
      </el-table-column>
      <el-table-column label="T幣">
        <template slot-scope="scope">
          <span class="tCoinColumn">
            {{ `${scope.row.point}/${scope.row.point_sum}` }}
            <el-button
              type="primary"
              size="small"
              @click="() => {
                isGiveTCoin = true;
                updateTCoinDialogVisible = true;
                currentUser = scope.row;
              }"
            >
              <i class="fas fa-edit" />
            </el-button>
            <router-link
              class="seePayment"
              :to="{ name: 'ToeflPayments', query: { email: scope.row.email } }"
            >
              <el-button type="primary" size="small">
                <i class="fas fa-eye" />
              </el-button>
            </router-link>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Pro 使用期限" width="180px">
        <template slot-scope="scope">
          <span v-if="scope.row.taken_unlimited_expire_at">
            {{
              moment(scope.row.taken_unlimited_expire_at).format("YYYY-MM-DD")
            }}
          </span>
          <span v-else>
            -
          </span>
          <el-button
            type="primary"
            size="small"
            @click="() => {
              updateProDialogVisible = true;
              currentUser = scope.row;
            }"
          >
            <i class="fas fa-edit" />
          </el-button>
          <el-button
            v-if="scope.row.taken_unlimited_expire_at"
            type="danger"
            size="small"
            @click="() => removePro(scope.row.id)"
          >
            <i class="fas fa-user-minus" />
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Pro 使用種類" width="110px">
        <template slot-scope="scope">
          <span v-if="scope.row.taken_unlimited_expire_at">
            {{ getProKind(scope.row.all_roles) }}
            <el-button
              type="primary"
              size="small"
              @click="() => switchProStatusPermission(scope.row.id)"
            >
              <i class="el-icon-refresh" />
            </el-button>
          </span>
          <span v-else>
            -
          </span>
        </template>
      </el-table-column>
      <el-table-column label="註冊日期" width="120px">
        <template slot-scope="scope">
          {{ instant.formatGoogleTime(scope.row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column label="IP">
        <template slot-scope="scope">
          <span v-if="scope.row.last_ip">
            <template v-if="scope.row.privacy_info">
              ({{ scope.row.privacy_info[0] }})
            </template>
            {{ scope.row.last_ip }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="15"
        :current-page="students.current_page"
        @current-change="fetchStudents"
        :total="students.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="設定 Pro 會員有效日期"
      :visible.sync="updateProDialogVisible"
      width="400px"
    >
      <el-date-picker
        class="datePicker"
        type="date"
        v-model="newExpiredDate"
        :picker-options="pickerOptions"
      />
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="() => {
            updateProDialogVisible = false;
            currentUser = null;
            newExpiredDate = null;
          }"
        >
          {{ $t("alert.cancel") }}
        </el-button>
        <el-button type="primary" @click="updateProExpiredDate">
          {{ $t("alert.confirm") }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-if="currentUser"
      title="修改使用者T幣"
      :visible.sync="updateTCoinDialogVisible"
      width="400px"
    >
      <div class="giveTCoinRadio">
        <el-radio-group v-model="isGiveTCoin">
          <el-radio :label="true">儲值</el-radio>
          <el-radio :label="false">扣款</el-radio>
        </el-radio-group>
      </div>
      <el-input-number
        v-model="newTCoin"
        :min="0"
        :max="isGiveTCoin ? Number.MAX_SAFE_INTEGER : currentUser.point"
        :step="1"
        step-strictly
        style="width: 100%"
      />
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="() => {
            updateTCoinDialogVisible = false;
            newTCoin = null;
          }"
        >
          {{ $t("alert.cancel") }}
        </el-button>
        <el-button type="primary" @click="updateTCoin">
          {{ $t("alert.confirm") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { user, instant } from "@ivy-way/material";
import PageTitle from "@/components/PageTitle.vue";
import userApi from "@/apis/user";

export default {
  metaInfo() {
    return {
      title: "Students - " + this.CompanyName
    };
  },
  components: {
    PageTitle
  },
  computed: {
    userMethods() {
      return user;
    },
    instant() {
      return instant;
    },
    moment() {
      return moment;
    },
    pickerOptions() {
      return {
        disabledDate: date => {
          let startDate = moment();
          return moment(date).isBefore(startDate);
        }
      };
    }
  },
  data() {
    return {
      students: {
        data: []
      },
      keyword: "",
      updateProDialogVisible: false,
      updateTCoinDialogVisible: false,
      currentUser: null,
      newExpiredDate: null,
      newTCoin: null,
      isGiveTCoin: false
    };
  },
  watch: {
    isGiveTCoin() {
      this.newTCoin = null;
    }
  },
  async created() {
    this.fetchStudents();
  },
  methods: {
    getProKind(roles) {
      return roles.includes("old-pro") ? "Old" : "New";
    },
    async fetchStudents(page = 1) {
      const { users } = await userApi.getUsers({ keyword: this.keyword, page });
      this.students = users;
    },
    async removePro(userId) {
      try {
        await userApi.removePro(userId);
        this.fetchStudents(this.students.current_page);
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async updateProExpiredDate() {
      try {
        await userApi.updateUserProExpiredDate(
          this.currentUser.id,
          moment(this.newExpiredDate).format("YYYY/MM/DD")
        );
        this.fetchStudents(this.students.current_page);
        this.updateProDialogVisible = false;
        this.currentUser = null;
        this.newExpiredDate = null;
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async updateTCoin() {
      try {
        if (this.isGiveTCoin) {
          await userApi.giveTCoin(this.currentUser.id, this.newTCoin);
        } else {
          await userApi.deductTCoin(this.currentUser.id, this.newTCoin);
        }
        this.fetchStudents(this.students.current_page);
        this.updateProDialogVisible = false;
        this.currentUser = null;
        this.newTCoin = null;
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async switchBlockStatus(userId) {
      try {
        await userApi.switchBlockStatus(userId);
        await this.fetchStudents(this.students.current_page);
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async switchProStatusPermission(userId) {
      try {
        await userApi.switchProStatusPermission(userId);
        await this.fetchStudents(this.students.current_page);
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  padding-top: 40px;
}

.search {
  display: flex;
}

.datePicker {
  width: 100%;
}

.tCoinColumn {
  white-space: nowrap;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

::v-deep .el-button--small {
  padding: 4px 6px;
}

.seePayment {
  padding: 4px 6px;
}

.giveTCoinRadio {
  margin-bottom: 12px;
}
</style>
